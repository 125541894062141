<template>
  <div>
    <b-modal
      id="modal-profile"
      ref="my-modal"
      cancel-variant="outline-secondary"
      ok-variant="primary"
      ok-title="Salvar"
      cancel-title="Sair"
      centered
      title="Meu Perfil"
      @ok="handleOk"
    >
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit">
          <b-form-group>
            <label for="email">Email</label>
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
              disabled
            />
          </b-form-group>

          <b-form-group
            label="Senha Atual"
            label-for="current-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Senha Atual"
              vid="password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="current-password"
                  v-model="userData.currentPassword"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="current-password"
                  autocomplete="off"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Nova Senha"
            label-for="new-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Nova Senha"
              vid="new_password"
              rules="required|password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="new-password"
                  v-model="userData.newPassword"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="newPasswordFieldType"
                  name="new-password"
                  autocomplete="off"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="newPasswordToggleIcon"
                    @click="toggleNewPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <password-meter
                v-model="userData.newPassword"
                :strength-meter-only="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import PasswordMeter from 'vue-password-strength-meter'
import {
  BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BModal,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, password, email as EmailValidator } from '@validations'
import store from '@/store'
import profileStoreModule from '@/views/profile/profileStoreModule'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    PasswordMeter,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      passwordFieldType: 'password',
      newPasswordFieldType: 'password',
      required,
      password,
      email: EmailValidator,
    }
  },
  setup() {
    const { email } = getUserData()
    const loading = ref(false)
    const userData = ref({ email })

    const PROFILE_APP_STORE_MODULE_NAME = 'app-profile'

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) store.registerModule(PROFILE_APP_STORE_MODULE_NAME, profileStoreModule)

    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify({ email }))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    async function handleSubmit() {
      if (loading.value) return
      loading.value = true
      await this.$recaptchaLoaded()
      const recaptcha = await this.$recaptcha('login')
      const { currentPassword, newPassword } = userData.value
      store.dispatch('app-profile/updateProfile', { password: currentPassword, new_password: newPassword, recaptcha })
        .then(() => {
          loading.value = false
          resetUserData()
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
        })
        .catch(error => {
          loading.value = false
          const { errors } = error.response.data
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(errors)) {
            this.$refs.refFormObserver.setErrors({
              [key]: value,
            })
          }
        })
    }

    function handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    }

    return {
      loading,
      userData,
      handleSubmit,
      handleOk,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    toggleNewPasswordVisibility() {
      this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
