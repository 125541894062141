// import { getUserData } from '@/auth/utils'

export default [
  {
    title: 'Insights',
    route: 'home',
    icon: 'TrendingUpIcon',
    action: 'read',
    resource: 'Home',
  },
  {
    title: 'Unidades/Escalas',
    route: 'workplaces',
    icon: 'MapPinIcon',
    tagVariant: 'light-success',
    action: 'read',
    resource: 'Workplace',
  },
  {
    title: 'Médicos',
    route: 'physician-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Physician',
  },
  {
    title: 'Relatórios',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'Report',
    children: [
      {
        title: 'Adiantamentos',
        route: 'reports',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'Report',
      },
      {
        title: 'Prestação de contas',
        route: 'reports-accountability',
        icon: 'DollarSignIcon',
        action: 'read',
        resource: 'Report',
      },
    ],
  },
  {
    title: 'Autorizações',
    route: 'authorizations',
    icon: 'UserCheckIcon',
    action: 'edit',
    resource: 'Authorization',
  },
  {
    title: 'Importações',
    icon: 'UploadCloudIcon',
    action: 'read',
    resource: 'Import',
    children: [
      {
        title: 'Médicos',
        route: 'import-physician',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'ImportPhysician',
      },
    ],
  },
  // {
  //   title: 'Processos',
  //   href: () => {
  //     const user = getUserData()
  //     return user ? `https://${user.aws_token.user}:${user.aws_token.password}@processo-cliente.imediato.online` : ''
  //   },
  //   icon: 'SettingsIcon',
  //   action: 'read',
  //   resource: 'BusinessProcess',
  //   target: '_blank',
  // },
  {
    header: 'Admin',
    user_access_types: ['gestor'],
  },
  {
    title: 'Usuários',
    route: 'users-list',
    icon: 'UsersIcon',
    user_access_types: ['gestor'],
    action: 'list',
    resource: 'User',
  },
]
